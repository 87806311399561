import { useQuery, useResult } from "@vue/apollo-composable";
import { isEmpty } from "lodash";
import { useToast } from "vue-toastification";
const toast = useToast();
import { useI18n } from "vue-i18n";

/**
 * Return an list of element(s)
 * @param {*} currentPage | null page requested
 * @param {*} perPage | null  number of element per page
 * @param {*} filter | null filter object
 * @param {*} query graphQL query
 * @returns
 */
export default function useGetList(
  currentPage,
  perPage,
  filter,
  query,
  options = {}
) {
  const { t } = useI18n({ useScope: "global" });

  const { result, loading, error, onError, refetch } = useQuery(
    query,
    () => ({
      requestedPage: isEmpty(currentPage) ? null : currentPage.value,
      limit: !isEmpty(perPage) ? parseInt(perPage.value) : null,
      filter: isEmpty(filter) ? null : filter
    }),
    isEmpty(options) ? {} : options.value
  );
  const items = useResult(result, [], data => data.result.docs);
  const lastPage = useResult(result, null, data => data.result.totalPages);

  onError(({ graphQLErrors, networkError }) => {
    let error = '';
    if (graphQLErrors) {
      error += graphQLErrors.map(({ message, locations, path }) =>`[GraphQL error]: Message: ${message}`)
    }
    if (networkError) {
      // Add something like this to set the error message to the one from the server response
      error+=networkError.result.errors.map(error => `[Network error]: ${error.message}`);
    };
    toast(t("form.error.generic_message.query")+'\n'+error, {
      timeout: false,
      type: "error",
      id: "error-toast"
    });
  });

  return {
    loading: loading,
    error: error,
    items: items,
    lastPage: lastPage,
    refetch: refetch
  };
}
