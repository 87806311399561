<template>
  <router-link
    v-if="routeNameReadEntity"
    :to="{
      name: routeNameReadEntity,
      params: { id: id }
    }"
  >
    <va-icon :id="id + '_button_read'" :title="$t('common.see_details')" class="material-icon" name="visibility" />
  </router-link>
  <va-button
    :id="id + '_button_delete'"
    v-if="routeNameDeleteEntity"
    data-cy="delete-button"
    @click="openModal(id)"
    color="danger"
    icon="clear"
    class="mr-4"
    >{{ $t('common.delete') }}</va-button
  >
  <router-link
    :id="id + '_button_update'"
    v-if="routeNameUpdateEntity"
    :to="{
      name: routeNameUpdateEntity,
      params: { id: id }
    }"
  >
    <va-icon :title="$t('common.update')" data-cy="edit-button" class="material-icon ml-2" name="pencil" />
  </router-link>
  <va-modal
    ref="deleteModal"
    stateful
    :message="$t('form.delete_confirmation_generic_message')"
    :title="$t('form.delete_title_generic')"
    @ok="deleteElement"
  />
</template>

<script>
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';
import DeleteModal from '../modal/DeleteModal';
import { useToast } from 'vue-toastification';
const toast = useToast();
import { useI18n } from 'vue-i18n';
import { useFormMutation } from '../helpers/useFormMutation';
export default {
  props: {
    id: { type: String, required: true },
    routeNameUpdateEntity: { type: String, required: false, description: 'The name of the route to update a element' },
    routeNameDeleteEntity: { type: String, required: false, description: 'The name of the route to delete a element' },
    routeNameReadEntity: { type: String, required: false, description: 'The name of the route to see a element' },
    mutationDeleteElement: { type: String, required: true }
  },
  components: { DeleteModal },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });

    const deleteMutation = useFormMutation(props.mutationDeleteElement);

    const executeDeleteMutation = async _id => {
      const data = { _id };
      await deleteMutation.mutation(data);
    };

    return {
      deleteId: '',
      loading: deleteMutation.loading,
      showModal: true,
      executeDeleteMutation: executeDeleteMutation
    };
  },

  methods: {
    openModal(deleteId) {
      this.deleteId = deleteId;
      this.$refs.deleteModal.show();
    },
    deleteElement() {
      this.executeDeleteMutation({ id: this.deleteId });
    }
  }
};
</script>
