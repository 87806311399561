<template>
  <div class="markup-tables">
    <div class="table-wrapper">
      <table class="va-table va-table--striped" data-cy="table-list">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.id" :id="column.id">
              {{ $t(column.label) }}
            </th>
            <th v-if="routes">{{ $t('common.actions') }}</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length > 0">
          <tr v-for="item in items" :key="item._id" :id="item._id">
            <td data-cy="table-element" v-for="column in columns" :key="column.key">
              {{ column.value(item, $t) }}
            </td>
            <td v-if="routes">
              <actions
                :id="item._id"
                :routeNameReadEntity="routes.read"
                :routeNameUpdateEntity="routes.update"
                :routeNameDeleteEntity="routes.delete"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { ref, watch, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Actions from './Actions';
import { ColumnDef } from '@/components/helpers/Column';

export default {
  props: {
    columns: { type: Array, required: true },
    items: { type: [Object], default: [] },
    routes: { type: Object, required: false }
  },
  components: { Actions }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
  .va-loading {
    padding-top: 10%;
  }
}
</style>
