<template>
  <p>
    <va-modal ref="deleteModalConfirmation" v-model="showModal" :message="$t('form.delete_confirmation_generic_message')" :title="$t('form.delete_title_generic')"/>
  </p>
</template>
  
<script>

export default {
  props: {validate: !Function, showModal: !Boolean},
}
</script>