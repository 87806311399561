<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <div name="card-actions" class="flex mb-4 mt-3">
          <router-link :to="{ name: routes.create }" v-if="routes.create">
            <va-button class="mr-3" color="secondary" data-cy="create-button" icon-right="add">{{
              $t(createButtonName)
            }}</va-button>
          </router-link>
          <slot name="actions"> </slot>
        </div>
        <va-inner-loading :loading="loading" color="primary" class="va-loading" :size="60" v-if="loading" />
        <div style="padding: 0.75rem">
          <list-table :items="items" :columns="columns" :routes="routes" />
        </div>
        <div class="pagination">
          <va-select
            v-model="perPage"
            :options="[10, 20, 50]"
            :label="$t('common.per_page')"
            style="max-width: 200px"
            class="mr-2"
          />
          <va-pagination v-model="currentPage" input :pages="lastPage" />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { GET_PROPERTIES } from '@mdb/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { ref, onMounted, watch, defineComponent, toRefs, reactive } from 'vue';
import ListTable from './ListTable.vue';
import { isEmpty } from 'lodash';
// import FilterList from "./FilterList.vue";
import useGetList from '@/components/helpers/useGetList';
import { ColumnDef } from '@/components/helpers/Column';

export default defineComponent({
  props: {
    columns: { type: [], required: true },
    filter: { type: Object, required: false },
    createButtonName: { type: String, default: 'form.create', description: 'Label for the create button' },
    routes: { type: Object, required: false, default: () => {} },
    queryGetList: { type: String, required: true },
  },
  components: { ListTable },
  setup(props, { emit }) {
    const currentPage = ref(1);
    const options = ref({ immediate: true, notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' });
    const perPage = ref(process.env.VUE_APP_PER_PAGE_DEFAULT);

    const { filter } = toRefs(props);
    onMounted(() => {
      console.log('mounter');
    });
    watch(
      filter,
      (value) => {
        console.log('in list');
      },
      { deep: true }
    );

    const { items, lastPage, loading, error, refetch } = useGetList(
      currentPage,
      perPage,
      filter.value,
      props.queryGetList,
      options
    );

    watch(loading, (newValue) => {
      emit('updateLoading', newValue);
    });

    return {
      loading: loading,
      error: error,
      items: items,
      lastPage: lastPage,
      columns: props.columns,
      currentPage: currentPage,
      refresh: refetch,
      perPage: perPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}
</style>
