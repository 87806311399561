import { UserRolesEnum } from '@mdb/core';
import AuthenticationService from './AuthenticationService';
var objectPath = require('object-path');

export const formatNumber = (value?: string | number) => value?.toLocaleString();

export const formatMoney = (value?: string | number) => formatNumber(value)?.concat(' €');
export const formatPercentage = (value?: string | number) => formatNumber(value)?.concat(' %');

export const formatSize = (value?: string | number) => formatNumber(value)?.concat(' m2');

export const formatDate = (value: string) => new Date(value).toLocaleDateString();

export const formatStringArray = (value: string[]) => value.join(' ');

interface IColumnDef {
  root?: String;
  key: String;
  id: String;
  label: String;
  i18nRootPath?: String;
  format?: Function;
  orderBy?: Boolean;
  preffix?: String;
  roles?: UserRolesEnum[];
}

export class ColumnDef {
  // html id of the element
  public id: String;
  // the path in the object, example address.street, will allows to retrieve the value
  public key: String;
  // use friendly name to display, example for address.street: Street
  protected label: String;
  // need if the value need to be translated. For example the value HOUSE need to be translated
  protected i18nRootPath?: String;
  // the function to format the value
  protected format?: Function;
  //if the column can be ordered
  protected orderBy?: Boolean;
  // if the path is inside the array precise the root name
  protected root?: String;
  // preffix for the label
  protected preffix?: String;
  // indicate is the column need to have roles
  public roles?: UserRolesEnum[];

  constructor(columnsDef: IColumnDef) {
    this.key = columnsDef.key;
    this.id = columnsDef.id;
    this.label = columnsDef.label;
    this.i18nRootPath = columnsDef.i18nRootPath;
    this.format = columnsDef.format;
    this.orderBy = columnsDef.orderBy;
    this.root = columnsDef.root;
    this.preffix = columnsDef.preffix;
    this.roles = columnsDef.roles;
  }

  public value = (object: any, t: Function, index?: number) => {
    let path = this.key;
    if (this.root) {
      path = `${this.root}.${index}.${this.key}`;
    }
    const value = objectPath(object).get(path);
    // format if necessary||
    if (this.format) {
      return this.format(value);
    }
    // verify if the value need to be translated
    if (t && this.i18nRootPath) {
      // retrieve t value
      return t(this.i18nRootPath.concat('.').concat(value));
    }
    // if not return directly the value
    return value;
  };
}

export class Column {
  private columnsDef: Map<String, ColumnDef> = new Map();

  constructor(columnsDef: Map<String, ColumnDef>) {
    this.columnsDef = columnsDef;
  }

  public getColumns = (keys?: String[]): ColumnDef[] => {
    // filter first on roles
    const list = new Map(
      [...this.columnsDef].filter(([key, column]) => !column.roles || AuthenticationService.hasRoles(column.roles))
    );
    // if nothing is given as parameters we return all the column
    if (!Boolean(keys) || keys?.length === 0) {
      return Array.from(list.values());
    } else {
      const result = new Map([...list].filter(([key]) => keys?.find(elt => key.valueOf() == elt.valueOf())));
      return Array.from(result.values());
    }
  };
}
